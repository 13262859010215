import React, { useState, useEffect } from "react";
import { getC19TitleListsData } from "../../api/getData";
import { COMPONENT_C19_TITLE_LISTS_COMPONENT } from "../../constants/componentTypes";


export default function Title({ componentID, resourceVersionId, currentCountryCode }) {
    const [C19TitleListsData, setC19TitleListsData] = useState(null);

    useEffect(() => {
        getC19TitleListsData(COMPONENT_C19_TITLE_LISTS_COMPONENT, componentID, setC19TitleListsData, resourceVersionId, currentCountryCode);
    }, [componentID, currentCountryCode, resourceVersionId]);

    return (
        <>
            {
                C19TitleListsData?.attributes && (
                    <>
                        <a href={C19TitleListsData?.attributes?.field_url?.uri.replace("internal:", "")} target={C19TitleListsData?.attributes?.field_url_open_type}>{C19TitleListsData?.attributes?.field_title_1}</a>
                    </>
                )
            }
        </>
    );
};
