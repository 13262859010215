import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import NotFound from "../components/NotFound/NotFound";
import Page from "../containers/Page";
import MultiCountryLan from "../components/MultiCountryLan/MultiCountryLan";
import { getCustomCurrentRoutePageUrls, getCurrentRoutePageUrls, getIPBasedPageUrls, getPageLanguages, getReviewSummary } from "../api/getData";
import Loader from "../components/Loader/Loader";
import AllComponent from "../containers/AllComponent/index";
import Layout from "../components/Layout/Layout";
import SearchResult from "../components/SearchResult/SearchResult";
import Testimonials from "../components/Testimonials/Testimonials";
import { useNavigate } from 'react-router-dom';

function useHashChange(callback) {
  useEffect(() => {
    const handleHashChange = () => {
      callback(window.location.hash);
    };

    window.addEventListener('hashchange', handleHashChange);
    handleHashChange();

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [callback]);
}

function Pages() {
  const location = useLocation();
  const page = location.pathname;
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);
  const preview = searchParams.get('preview');
  const uuid = searchParams.get('uuid');
  const resourceVersionId = searchParams.get('resourceVersionId');
  const [GlobalPageURL, setGlobalPageURL] = useState(null);
  const [pageURL, setPageURL] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('default');
  const [selectedCountry, setSelectedCountry] = useState('default');
  const [ipLocation, setIpLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentCountryCode, setLanguages] = useState('');
  const [ReviewSummary, setReviewSummary] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = location.pathname + location.search + location.hash;
    const lowerCasePath = location.pathname.toLowerCase() + location.search + location.hash;
    if (currentPath !== lowerCasePath) {
      navigate(lowerCasePath, { replace: true });
    }
  }, [location.hash, location.pathname, location.search, navigate]);
  function getDistanceToBody(element) {
    let distance = 0;
    while (element && element !== document.body) {
      distance += element.offsetTop;
      element = element.offsetParent; // 逐层向上查找
    }
    return distance;
  }
  useHashChange((hash) => {
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      const topDiv = document.getElementById('top');
      let topHeight = 0;
      if (topDiv) {
        topHeight = topDiv.offsetHeight;
      } else {
        topHeight = 146;
      }
      if (element) {
        setTimeout(() => {
          window.scrollTo({
            top: getDistanceToBody(element) - topHeight,
            behavior: 'smooth',
          });
        }, 100);
      }

    }
  });

  useEffect(() => {
    getReviewSummary(setReviewSummary);
    getPageLanguages(page, setLanguages);
    //getIPBasedPageUrls(setIpLocation);
    if (selectedCountry === 'default' && selectedLanguage === 'default') {
      getCurrentRoutePageUrls(setGlobalPageURL, setLoading, page, currentCountryCode);
    } else {
      getCustomCurrentRoutePageUrls(setPageURL, setLoading, selectedLanguage, selectedCountry, page, currentCountryCode);
    }
  }, [selectedCountry, selectedLanguage, page, currentCountryCode]);

  if (page === "/country-language") {
    return (
      <>
        {loading ? <Loader /> : <Layout><MultiCountryLan language={setSelectedLanguage} country={setSelectedCountry} /></Layout>}
      </>
    )
  } else if (page === "/test-all-components") {
    return (
      <>
        {loading ? <Loader /> : <Layout><AllComponent /></Layout>}
      </>
    )
  } else if (page === "/search-result-test") {
    return (
      <>
        {loading ? <Loader /> : <Layout><SearchResult /></Layout>}
      </>
    )
  }
  else if (page === "/testimonials") {
    return (
      <>
        {loading ? <Loader /> : <Layout><Testimonials /></Layout>}
      </>
    )
  }
  else if (page) {
    if (preview) {
      return (
        <>
          {loading ? <Loader /> : <><Layout>
            <Page
              locator={`${page}`}
              pageID={`${uuid}`}
              resourceVersionId={resourceVersionId}
              currentCountryCode={currentCountryCode}
            />
          </Layout>
          </>}
        </>
      )
    }
    else if (JSON.stringify(GlobalPageURL) === JSON.stringify([[]])) {
      return (
        <>
          {loading ? <Loader /> : <Layout><NotFound /></Layout>}
        </>
      )
    }
    else if (GlobalPageURL !== null) {
      return (
        <>
          {loading ? <Loader /> :
            (GlobalPageURL.map((url) =>
            (
              <>
                <Layout>
                  {url.map((globalPage) => {
                    if (url.length > 1 && !globalPage.attributes.status) {
                      return null;
                    } else if (!globalPage.attributes.status) {
                      return <NotFound />;
                    }
                    return (
                      <Page
                        locator={`${globalPage.attributes.field_page_url}`}
                        pageID={`${globalPage.id}`}
                        resourceVersionId={resourceVersionId}
                        currentCountryCode={currentCountryCode}
                      />
                    );
                  })}
                </Layout>
              </>
            )
            )
            )
          }
        </>
      )
    }
    else if (JSON.stringify(pageURL) === JSON.stringify([[]])) {
      return (
        <>
          {loading ? <Loader /> : <Layout><NotFound /></Layout>}
        </>
      )
    }
    else if (pageURL !== null) {
      return (
        <>
          {loading ? <Loader /> :
            (pageURL.map((url) =>
            (
              <><Layout>
                {url.map((page) => (
                  page.attributes.status ? (
                    <Page
                      locator={`${page.attributes.field_page_url}`}
                      pageID={`${page.id}`}
                      resourceVersionId={resourceVersionId}
                    />
                  ) : <NotFound />
                ))}</Layout>
              </>
            )
            )
            )
          }
        </>
      )

    }
    else {
      return (
        <>
          {loading ? <Loader /> : <Layout><NotFound /></Layout>}
        </>
      )

    }

  }
}

export default Pages;