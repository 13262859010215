
import React, { useState, useEffect } from "react";
import { getC18PointData } from "../../api/getData";
import greenArrowIcon from "../C18_PointLists/green-arrow-icon.svg";
const Point = ({ componentID, resourceVersionId, currentCountryCode }) => {
    const [C18PointData, setC18PointData] = useState(null);
    const formatUrl = (url) => {
        if (url && url.startsWith('/') && currentCountryCode) {
            return url.replace(/^\/+/, '/' + currentCountryCode + '/');
        }
        return url;
    };
    useEffect(() => {
        getC18PointData(componentID, setC18PointData, resourceVersionId, currentCountryCode);
    }, [componentID, currentCountryCode, resourceVersionId]);
    return (
        <>
            {C18PointData && (
                <>
                    <span>
                        <img alt="item-svg" className="cont-item-svg" height="15" src={greenArrowIcon} width="8" />
                    </span>
                    <div>

                        {C18PointData.field_prefix_character_bold ? (
                            <b>
                                {C18PointData.field_prefix_character_url ? (
                                    <a href={formatUrl(C18PointData.field_prefix_character_url)} target={C18PointData.field_prefix_char_url_open_type || "_blank"} rel="noreferrer">
                                        {C18PointData.field_prefix_character}
                                    </a>
                                ) : (
                                    C18PointData.field_prefix_character
                                )}
                            </b>
                        ) : (
                            C18PointData.field_prefix_character_url ? (
                                <a href={formatUrl(C18PointData.field_prefix_character_url)} target={C18PointData.field_prefix_char_url_open_type || "_blank"} rel="noreferrer">
                                    {C18PointData.field_prefix_character}
                                </a>
                            ) : (
                                C18PointData.field_prefix_character
                            )
                        )}
                        &nbsp;
                        {C18PointData.field_middle_character_bold ? (
                            <b>
                                {C18PointData.field_middle_character_url ? (
                                    <a href={formatUrl(C18PointData.field_middle_character_url)} target={C18PointData.field_middle_char_url_open_type || "_blank"} rel="noreferrer">
                                        {C18PointData.field_middle_character}
                                    </a>
                                ) : (
                                    C18PointData.field_middle_character
                                )}
                            </b>
                        ) : (
                            C18PointData.field_middle_character_url ? (
                                <a href={formatUrl(C18PointData.field_middle_character_url)} target={C18PointData.field_middle_char_url_open_type || "_blank"} rel="noreferrer">
                                    {C18PointData.field_middle_character}
                                </a>
                            ) : (
                                C18PointData.field_middle_character
                            )
                        )}
                        &nbsp;
                        {C18PointData.field_postfix_character_bold ? (
                            <b>
                                {C18PointData.field_postfix_character_url ? (
                                    <a href={formatUrl(C18PointData.field_prefix_character_url)} target={C18PointData.field_postfix_char_url_open_type || "_blank"} rel="noreferrer">
                                        {C18PointData.field_postfix_character}
                                    </a>
                                ) : (
                                    C18PointData.field_postfix_character
                                )}
                            </b>
                        ) : (
                            C18PointData.field_postfix_character_url ? (
                                <a href={formatUrl(C18PointData.field_prefix_character_url)} target={C18PointData.field_postfix_char_url_open_type || "_blank"} rel="noreferrer">
                                    {C18PointData.field_postfix_character}
                                </a>
                            ) : (
                                C18PointData.field_postfix_character
                            )
                        )}
                    </div>
                </>
            )}
        </>
    );
};
export default Point;
