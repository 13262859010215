import React, { useState, useEffect } from 'react';
import Packery from 'packery';
import styles from "../Testimonials/Testimonials.module.css";

const DraggableGrid = (props) => {
    //class DraggableGrid extends Component {
    const renderStars = (numStars) => {
        let starHTML = "";

        // 整数部分的星星
        const integerStars = Math.floor(numStars);
        for (let i = 0; i < integerStars; i++) {
            starHTML += '<i class="bi bi-star-fill"></i>';
        }

        // 半颗星
        if (numStars - integerStars >= 0.5) {
            starHTML += '<i class="bi bi-star-half"></i>';
        }

        return (
            <div dangerouslySetInnerHTML={{ __html: starHTML }} className={`${styles['bi-interval']}`}></div>
        );
    }

    function formatDate(dateString) {
        const inputDate = new Date(dateString);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };
        const formattedDate = inputDate.toLocaleDateString("en-US", options);
        return formattedDate;
    }
    useEffect(() => {
        const grid = new Packery('.draggable-container', {
            itemSelector: '.draggable-div',
            layoutMode: 'masonry'
        });

        const handleResize = () => {
            grid.layout();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [props.items]);


    return (
        <div className={`${!props.items ? styles['no-data-wrapper'] : ''} draggable-container`} >
            {!props.items ?
                (
                    <div className={styles['no-found']}>
                        <img alt="no-data" className={styles['no-img']} src="/packimpex/images/NL_icons/svg_icons/no-data.svg" />
                        <div className={styles['no-found-text']}>Sorry, no matches were found.</div>
                    </div>
                )
                : (props.items.map((item, index) => (
                    <><div class="col-lg-6 col-xs-12 draggable-div" key={index}>

                        <div dangerouslySetInnerHTML={{
                            __html: `
            <script type="application/ld+json">
            {
              "@context": "http://schema.org/",
              "@type": "Review",
              "itemReviewed": {
                "@type": "Organization",
                "name": "Packimpex"
              },
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": "${item.Rating}",
                "bestRating": "5"
              },
              "name": "${item._id}",
              "author": {
                "@type": "Person",
                "name": "${item.Client_Name}"
              },
              "reviewBody": "${item.Description}"
            }
            </script>
                `}} />
                        <div className={styles['chat-box']}>
                            <div className={styles['row-flex']}>
                                <div className={styles['star-box']}>

                                    {renderStars(item.Rating)}

                                </div>
                                <div className={styles['chat-date']}>{formatDate(item.Review_Date)}</div>
                            </div>
                            <div className={`${styles['row-flex']} ${styles['chat-txt']}`}>
                                <div>{item.Description}</div>
                            </div>

                            <div className={styles['chat-name']}>
                                <div className={styles['c-name']}>{item.Client_Name}</div>
                                <div className={styles['text-abroad']}>{item.Location_Product_Other}</div>

                            </div>


                        </div>
                    </div></>
                )))
            }
        </div>
    );

}

export default DraggableGrid;