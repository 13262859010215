import React, { useEffect, useState } from "react";
import { getZigzagTriCardData } from "../../api/getData";
import { THREE_CARD_COMPONENT_WITH_TITLE } from "../../constants/componentTypes";
import styles from "../ZigzagTriCard/ZigzagTriCard.module.css";
import ZigzagTriCardSingle from "../ZigzagTriCard/ZigzagTriCardSingle";

const ZigzagTriCard = ({ componentID, resourceVersionId, currentCountryCode }) => {
  const [loading, setLoading] = useState(true);
  const [headerData, setHeaderData] = useState(null);
  const [zigzagTriCardData, setZigzagTriCardData] = useState(null);
  const [svg, setSvg] = useState(null);
  const [TitlePosition, setTitlePosition] = useState(null);

  useEffect(() => {
    getZigzagTriCardData(setHeaderData, setZigzagTriCardData, setSvg, THREE_CARD_COMPONENT_WITH_TITLE, componentID, setLoading, setTitlePosition, resourceVersionId, currentCountryCode);
  }, []);
  return (
    <>
      {loading ? null : (
        <>
          <div className={`${styles.tri__card__container}`}>
            <div className={`${styles.zigzagCard__area}`}>
              <div className="container">
                <div>
                  <div className={`${styles.zigzagCard__container}`}>
                    <h3 className={`${TitlePosition ? `title-position-${TitlePosition}` : ''}`}>{headerData}</h3>
                    <div className={`${styles.cardWithTitleImageDescButton__container}`}>

                      <div className={` ${styles.card__container}`}>
                        {zigzagTriCardData &&
                          zigzagTriCardData.map(
                            (data) => {
                              return (
                                <div className={` ${styles.individual__try}`}>
                                  <ZigzagTriCardSingle componentID={data.id} currentCountryCode={currentCountryCode} />
                                </div>
                              );
                            }
                          )}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {svg !== null && (
            <>
              <div
                className={styles.svg__block__1__up}
                dangerouslySetInnerHTML={{
                  __html: svg,
                }}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
export default ZigzagTriCard;
