import React, { useEffect, useState } from 'react'
import ComponentDecider from "../ComponentDecider"
import { useSelector } from "react-redux";
import CallIcon from "../../../assets/image/telephone-01.svg"
import MenuContentDesktop from "../MegaMenu/MenuContentDesktop"
export default function MenuDesktop(props) {
  const { setSearchActive } = props;
  const globalLinks = useSelector(state => state.menu.globalLinks);
  const showClick = () => {
    setSearchActive(true);
  };

  return (
    <><div className="header-bottom show-search-box">
      <div className="header-logo-outer">
        <a className="header-logo" href={globalLinks?.attributes?.field_logo}>
          <img className="" src="/logo.svg" alt="Logo Packimpex" width="100%" /></a>
        {/* <div className="start-sticky-wrap">
  <label>
    <a href="https://www.packimpex.ch/testimonials" target="_blank">
      <div className="start-sticky-total">
        <div>
          <i className="bi bi-star-fill"></i>
          <i className="bi bi-star-fill"></i>
          <i className="bi bi-star-fill"></i>
          <i className="bi bi-star-fill"></i>
          <i className="bi bi-star-half"></i>
        </div>
        <span>4.6</span> &nbsp;
        (4885 Reviews)
      </div>
    </a>
  </label>
</div> */}
      </div>
      <div className="header-nav header-nav-hk">
        <div className="header-nav-scroll">
          <ul className="lg-nav-items">
            {props?.items?.filter(item => item?.published === "1")
              .slice(0, 4)
              .map(item => (
                <li className="lg-nav-item nav-hover drop-down-nav" key={item.id}>
                  <a href={item?.mega_menu_url} target={item?.mega_menu_open_type} className="active-white">
                    <span>{item?.title}</span>
                  </a>
                  {item.submenu.length > 0 && <MenuContentDesktop key={item.id} keyId={item.id} data={item} />}
                </li>
              ))}

          </ul>
        </div>
      </div>
      <div className="header-tools">
        {/* <div className="header-menu-search-mb">
  <span className="header-icon-search"></span>
</div> */}
        <ul className="header-tools-right header-main">
          {globalLinks && globalLinks.attributes && globalLinks.attributes.field_get_in_touch_button_text &&
            <li className="header-register"><a href={globalLinks?.attributes?.field_get_in_touch_button_link} className="btn js-register"> {globalLinks?.attributes?.field_get_in_touch_button_text} </a></li>}
          {/* <li class="">
            <div class="top-bar-line"></div>
          </li>
          <li class="search-btn" onClick={showClick} > <img src="/packimpex/images/NL_icons/search.svg" alt="" /></li> */}
        </ul>
        <div class="get-button"><a href={globalLinks?.attributes?.field_get_in_touch_button_link}><img src={CallIcon} alt="" /></a></div>
        {/* <div class="get-button"><a onClick={showClick}><img src="/packimpex/images/NL_icons/search.svg" alt="" /></a></div> */}
        <div className="header-menu-mb hamburger material-icons" id="ham">
          {/* <div className="mb-menu">MENU</div>
<div className="mb-close">CLOSE</div> */}
          <div>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div></>
  )
}
