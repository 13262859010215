import React, { useEffect, useState } from 'react';
import {
  COMPONENT_C15_H1
} from "../../constants/componentTypes";
import { getC1H1Data } from '../../api/getData';
import styles from "../C15_H1/C15_index.module.css";

export default function C15_H1({ componentID, resourceVersionId, currentCountryCode }) {
  const [loading, setLoading] = useState(true);
  const [data, setPageData] = useState({});

  useEffect(() => {
    getC1H1Data(setPageData, COMPONENT_C15_H1, componentID, setLoading, resourceVersionId, currentCountryCode);
  }, []);
  return (
    <div className={`top-content-box container ${styles.margin_space}`}>
      <h1 class="ab-title" dangerouslySetInnerHTML={{ __html: data?.field_title }}></h1>
    </div>
  )
}
