import React from "react";
import { useState, useEffect } from "react";
import styles from "../TopBar/TopBar.module.css";
import TopBarButton from "./TopBarButton";
import { getUserIPData, getLoadCount } from "../../api/getData";
import CustomStorage from "../../storage/CustomStorage";
import { setTopbar } from "../../store/reducer/menu";
import { useDispatch } from 'react-redux';
import { setCookie, getCookie, HIDE_TOP_BAR_COOKIE } from '../../utils/cookies';

function TopBar() {
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [menuVisible, setMenuVisible] = useState(false);
  const [buttonMessage, setButtonMessage] = useState("");
  const [countryMessage, setCountryMessage] = useState("");
  const [otherMessage, setOtherMessage] = useState("");
  const [userOtherLanguage, setUserOtherLanguage] = useState([]);
  const [selectedValue, setSelectedValue] = useState(CustomStorage.get("selectedLanguage"));
  const dispatch = useDispatch();


  let url = window.location.href.split('/');
  let currentCountryCode = url[3];

  const handleChange = (language) => {
    setSelectedValue(language);
    CustomStorage.set("selectedLanguage", language);
  };

  const handleClose = () => {
    setCookie(HIDE_TOP_BAR_COOKIE, 'true', 30);
    setIsVisible(false);
  };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible)
  }

  const selectedLangauge = () => {
    let lang = otherMessage
    const selectedByCountry = userOtherLanguage && userOtherLanguage.filter((i) => i.languageURL === '/' + currentCountryCode);
    const selectedByValue = userOtherLanguage && userOtherLanguage.filter((i) => i.languageURL === selectedValue);
    if (selectedByValue.length) {
      lang = selectedByValue[0].languageName;
    } else if (selectedByCountry.length) {
      lang = selectedByCountry[0].languageName;
    }
    return lang
  }

  useEffect(() => {
    const updateTop = () => {
      const activeNavDrill = document.querySelector('.nav-drill.active');

      const chooseBg = document.querySelector('.choose-bg');
      const closeContent = document.querySelector('.close-content');
      const startSticky = document.querySelector('.start-sticky');
      const headerContainer = document.querySelector('.header-container.custom-header-css');

      const heights = [
        chooseBg ? chooseBg.offsetHeight : 0,
        closeContent ? closeContent.offsetHeight : 0,
        startSticky ? startSticky.offsetHeight : 0,
        isVisible && headerContainer ? headerContainer.offsetHeight : 0,
      ];

      const totalHeight = heights.reduce((acc, height) => acc + height, 0);

      if (activeNavDrill) {
        activeNavDrill.style.top = `${totalHeight}px`;
      }
    };
    dispatch(setTopbar(isVisible))

    if (isVisible) {
      setTimeout(() =>
        getUserIPData(
          setButtonMessage,
          setCountryMessage,
          setOtherMessage,
          setUserOtherLanguage,
          setSelectedValue,
          setLoading,
          setIsVisible,
          currentCountryCode
        ), 10000);
      setTimeout(() =>
        getLoadCount(
          currentCountryCode,
          setIsVisible
        ), 10000);

    }

    if (getCookie(HIDE_TOP_BAR_COOKIE)) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }

    if (!isVisible) {
      window.jQuery(".lag-nav-fade-header").removeClass("active");
      updateTop();
      window.jQuery(".header-nav-hk").removeClass("header-height");
      window.jQuery(".nav-drill").removeClass("active");
    }
    if (!loading && isVisible) {
      //MenuMobile.jsx add active ,Show TopBar
      window.jQuery(".nav-drill").addClass("active");
      updateTop();
      window.jQuery(".lg-nav-fade.lag-nav-fade-header").addClass("active");
      window.jQuery(".header-nav-hk").addClass("header-height");
    }
  }, [currentCountryCode, dispatch, isVisible, loading]);

  return (
    <>
      {!loading && isVisible && (
        <div className="choose-bg cloose-content">
          <div className="choose-content">
            <div className="choose-txt">{countryMessage}</div>
            <div className="right-select">
              <div className="has-select">
                <div className={`select-content ${(menuVisible) ? 'active' : ''}`} onClick={() => toggleMenu()}>
                  <div className="has-rep">
                    <span className="ls-dropdown-check"></span>
                    <p className="set">{selectedLangauge()}</p>
                    <span className="Angle-icon Angle-up"><img alt="Angle_up_new" src="/packimpex/images/NL_icons/Angle_up_new.png" /></span>
                    <span className="Angle-icon Angle-down"><img alt="Angle_down_new.png" src="/packimpex/images/NL_icons/Angle_down_new.png" /></span>
                  </div>
                  <ul className={`new ${(menuVisible) ? 'd-block' : ''}`}>
                    {userOtherLanguage &&
                      userOtherLanguage.map((data, index) => (
                        <li key={index} className={selectedValue === data.languageURL ? 'active' : ''} onClick={() => handleChange(data.languageURL)}>{data.languageName}</li>
                      ))}
                    <li onClick={() => handleChange('other')}>{otherMessage}</li>
                  </ul>
                </div>
              </div>
              {selectedValue ? (
                <TopBarButton
                  actionValue={selectedValue}
                  buttonMessage={buttonMessage}
                />
              ) : (
                <button className={`btn__green_outline ${styles.topbar__btn}`}>
                  {buttonMessage}
                </button>
              )}
              <button className={`btn-close-top ${styles.bar_close}`} onClick={handleClose}><img src="/packimpex/images/NL_icons/Close.png" alt="" /></button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TopBar;
