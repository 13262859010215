import styles from "../Footer/Social.module.css";
import { Fragment, React, useState, useEffect } from "react";
import IcomoonReact from "icomoon-react";
import iconSet from "./Font/selection.json";
import { getUserCountryData } from "../../api/getData";
import { useSelector } from "react-redux";
import axios from 'axios'
import APIS from '../../config/api'
import WhichMedia from "./WhichMedia";

const SocialMedia = () => {
  const [userCountry, setUserCountry] = useState("");
  const [otherMessage, setOtherMessage] = useState("");

  const globalLinks = useSelector(state => state.menu.globalLinks);
  let url = window.location.href.split('/');
  let currentCountryCode = url[3];
  let path = window.location.pathname;
  let cnt = 1;
  const [footerComponentDetail, setFooterComponentDetails] = useState([])
  const getComponentDetails = () => {
    axios.get(APIS.SOCIAL_MEDIA).then((res) => {
      if (res.data.data[0].attributes.status) {
        const data = res.data.data[0]?.relationships?.field_soical_media_link_and_imag?.data
        setFooterComponentDetails(data)
      }
    }).catch(() => {
      // console.log('error')
    }).then(() => {
      // console.log('error')
    })
  }
  useEffect(() => {
    getUserCountryData(setUserCountry, setOtherMessage, currentCountryCode)
    //getUserCountryData(setUserCountry,setOtherMessage);
    getComponentDetails();
  }, []);
  return (
    <div className="footer-links">
      <div className="container block-temp flex-row d-flex justify-content-end align-items-center">
        <div className="col-lg-4 footer_social">
          {
            footerComponentDetail && footerComponentDetail?.map((e) => {
              return <WhichMedia key={e.id} keyId={e.id}></WhichMedia>
            })
          }

          {/* <a target="_blank" href="https://www.facebook.com/packimpex/" className="footer_icon"><span className="icon-facebook"></span></a>
        <a target="_blank" href="https://twitter.com/packimpexsa" className="footer_icon"><span className="icon-twitter"></span></a>
        <a target="_blank" href="https://www.youtube.com/user/PackimpexSA" className="footer_icon"><span className="icon-youtube"></span></a>
        <a target="_blank" href="https://www.xing.com/pages/packimpexltd" className="footer_icon"><span className="icon-xing"></span></a> */}
        </div>
        <div className="col-lg-8 footer-r">
          <div className="menu-footer-menu-corporate-container">
            <ul id="menu-footer-menu-corporate" className="menu">
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1693 text-center">
                <a href={globalLinks?.attributes?.field_terms_condition_link}>{globalLinks?.attributes?.field_terms_condition_text}</a>
              </li>
              <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-14474 text-center privacy-content">
                <a href={globalLinks?.attributes?.field_privacy_link}>{globalLinks?.attributes?.field_privacy_text}</a>
              </li>
              <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-14474 text-center">
                <div className="footer-lag">
                  {userCountry && userCountry.map((data, index) => {
                    if (data.languageURL?.replace('/', '') !== currentCountryCode) {
                      return (
                        <Fragment key={index}>
                          <>
                            {cnt !== 1 &&
                              <span className="footer-line"> | </span>
                            }
                            {++cnt && null}
                            <span><a href={data.languageURL + path.replace('/' + currentCountryCode, '')} title={data.languageName}>{data.languageName}</a></span>
                            {userCountry.length - 1 === index &&
                              <>
                                <span className="footer-line"> | </span>
                                <span>  <a href="/country-language" title={otherMessage}>{otherMessage}</a></span>
                              </>}


                          </>
                        </Fragment>
                      )
                    } else {
                      return (
                        <Fragment key={index}>
                          <>
                            {userCountry.length - 1 === index &&
                              <>
                                {userCountry.length > 1 && <span className="footer-line"> | </span>}
                                <span> <a href="/country-language" title={otherMessage}>{otherMessage}</a></span>
                              </>
                            }


                          </>
                        </Fragment>
                      )
                    }
                    // if (userCountry.length === 1) {
                    //   return (
                    //     <Fragment key={index}>
                    //       <>
                    //         <span><a href={data.languageURL} title="Other country or region">{data.languageName}</a></span>
                    //       </>
                    //     </Fragment>
                    //   )
                    // }
                    // if (userCountry.length - 1 === index) {
                    //   return (
                    //     <Fragment key={index}>
                    //       <span className="footer-line"> | </span>
                    //       <span>{data.countryFooter}</span>
                    //     </Fragment>
                    //   )
                    // }
                  })}

                  {!userCountry &&
                    <span><a href="/country-language" title={otherMessage}>{otherMessage}</a></span>
                  }
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
