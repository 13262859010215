import React, { useState, useEffect } from "react";
import styles from "../Banner/Banner.module.css";
import { getBannerComponentData } from "../../api/getData";
import { BANNER_WITH_TITLE_IMAGE } from "../../constants/componentTypes";
import { useSelector } from "react-redux";

function Banner({ componentID, resourceVersionId, currentCountryCode }) {
  const topBarVisible = useSelector(state => state.menu.topBarActive);
  function handleClick() {
    window.scrollTo({
      top: window.scrollY + 730,
      behavior: "smooth",
    });
  }
  const [loading, setLoading] = useState(true);
  const [bannerData, setBannerData] = useState(null);
  const [image, setImage] = useState(null);
  const [imageAlt, setImageAlt] = useState(null);
  const [svg, setSvg] = useState(null);

  useEffect(() => {
    getBannerComponentData(setBannerData, setImage, setImageAlt, setSvg, BANNER_WITH_TITLE_IMAGE, componentID, setLoading, resourceVersionId, currentCountryCode);
  }, [componentID]);

  return (
    <>
      {loading ? <div style={{ width: "1600px", height: "700px" }}></div> : (
        <div className={`${styles.banner__container} top-banner ${topBarVisible ? 'active' : ''}`}>
          <div className={styles.arrowTransform}>
            <div onClick={handleClick} className={styles.arrow__button}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <picture>
            <source media="(max-width: 767px)" srcset={image ? image.replace("sites/default/files", "sites/default/files/styles/large/public") : image} />
            <img className={styles.banner__img} src={image} alt={imageAlt} width="1600" height="700" onError={(e) => {
              if (!e.target.dataset.error) {
                e.target.dataset.error = 'true';
                const source = e.target.parentElement.querySelector('source');
                source.srcset = image;
              }
            }} />
          </picture>

          <div className={styles.title__text}>
            <h2
              dangerouslySetInnerHTML={{
                __html: bannerData,
              }}
            />
          </div>

          {svg !== null && (
            <>
              <div
                className={styles.svg__block__1__up}
                dangerouslySetInnerHTML={{
                  __html: svg,
                }}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Banner;
