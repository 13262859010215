import MultiLanguage from "./MultiLanguage";

const MultiCountry = ({ countryLanguages }) => {

  return (
    <div className="py-80">
      {countryLanguages &&
        countryLanguages.slice(0, 4).map((data, index) => (
          <MultiLanguage key={index} languageID={data.id} />
        ))}
    </div>
  );
};

export default MultiCountry;


