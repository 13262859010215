import React, { useEffect, useState } from 'react'
import axios from 'axios'
import APIS from '../../../config/api'
import Image from '../Image'
import { Link } from "react-router-dom";
import Utils from '../../../config/utils'
import {
  DRUPAL_API_ENDPOINT
} from "../../../constants/drupalApiEndPoints";

export default function MenuItemTitleIcon(props) {
  const [componentDetail, setComponentDetails] = useState(props.item)
  // function getComponentDetails (id) {
  //   axios.get(APIS.MEGA_MENU_ITEM + id).then((res) => {
  //     const data = res.data.data
  //     setComponentDetails(data)
  //   }).catch(() => {
  //     // console.log('error')
  //   }).then(() => {
  //     // console.log('error')
  //   })
  // }
  // useEffect(() => {
  //   getComponentDetails(props.keyId)
  // }, [props.keyId])
  function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }
  return (
    <div className="col-lg-4 col-md-6 col-xs-6 nav-list">
      <a href={Utils.parseDrupalURL(componentDetail?.field_mega_menu_link)}>
        <div className="row">
          <div className="col-lg-3 col-xs-2">
            <div className="map-icon">
              <img src={DRUPAL_API_ENDPOINT + componentDetail?.field_mega_menu_icon} />
            </div>
          </div>
          <div className="col-lg-9 col-xs-10 text-left">
            {/* <h3>{componentDetail?.attributes?.field_mega_menu_title}</h3> */}
            <div className="small-text">{htmlDecode(componentDetail?.field_mega_menu_title) }
              {/* <span className="bi-arrow-right"></span> */}
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}