export const DRUPAL_API_ENDPOINT = process.env.REACT_APP_DRUPAL_API_ENDPOINT;
export const FETCH_ALL_URLS = process.env.REACT_APP_FETCH_ALL_URLS;
export const FETCH_SPECIFIC_COMPONENT = process.env.REACT_APP_FETCH_SPECIFIC_COMPONENT;
export const FETCH_MEDIA_FOR_THE_COMPONENT = process.env.REACT_APP_FETCH_MEDIA_FOR_THE_COMPONENT;
export const FETCH_MEDIA_DATA = process.env.REACT_APP_FETCH_MEDIA_DATA;
export const IP_API = process.env.REACT_APP_IP_API;
export const REQUEST_URL = process.env.REACT_APP_REQUEST_URL;
export const COMPANYCODE = process.env.REACT_APP_COMPANYCODE;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

