
import React, { useState, useEffect, useRef } from "react";
import styles from "../BannerWithTitleDescButton/TriCards.module.css";
import CardSlider from "../CardSlider/CardSlider";

const TriCards = ({ cardsData, currentCountryCode }) => {
    const [hasCards, setHasCards] = useState(false);
    const cardContainerRef = useRef(null);
    useEffect(() => {
        const observer = new MutationObserver(() => {
            // 监控到内容变化后检查是否有内容
            const hasVisibleCards = cardContainerRef.current.children.length > 0; // 检查是否有子元素
            setHasCards(hasVisibleCards);
        });
        observer.observe(cardContainerRef.current, { childList: true, subtree: true });
        
        // 清理
        return () => {
            observer.disconnect();
        };
    }, []);
    return (
        <>
            <div className={`${styles.tri__card__container}`} style={{ display: hasCards ? "block" : "none" }}>
                <div className="container">
                    <div className={`${styles.cardWithTitleImageDescButton__container}`}>
                        <div className={` ${styles.card__container}`} ref={cardContainerRef}>
                            {cardsData.map((data, index) => {
                                return (
                                    <CardSlider key={index} componentID={data.id} currentCountryCode={currentCountryCode} />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default TriCards;
