import React, { useState, useEffect } from 'react';
import APIS from '../../config/api'
import axios from "axios";
import Loader from "../Loader/Loader";
import styles from "../SearchResult/SearchResult.module.css";
const SearchResult = () => {
    const [loading, setLoading] = useState(true);
    const [SearchData, setSearchData] = useState(null);
    const [SearchDataNumber, setSearchDataNumber] = useState('');
    const [SearchText, setSearchText] = useState('');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlSearchText = urlParams.get('searchText');
    useEffect(() => {
        if (urlSearchText) {
            getSearchData(urlSearchText);
        }
    }, [urlSearchText]);
    const SearchClick = () => {
        if (document.getElementById('Search').value) {
            getSearchData(document.getElementById('Search').value);
        }

    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            SearchClick();
            document.getElementById('Search').blur();
        }
    };
    const getSearchData = (text) => {
        if (text) {
            setLoading(true);
            setSearchText(text);
            document.getElementById('Search').value = text;
            const searchBtn = document.getElementById('searchBtn');

            if (!searchBtn.disabled) {
                searchBtn.disabled = true;
                axios.get(APIS.SEARCH + '?searchText=' + text).then((res) => {
                    if (res.data.status === 1) {
                        setSearchData(res.data.search_result);
                        setSearchDataNumber(res.data.search_result.length);
                        setLoading(false);
                    } else {
                        setSearchData(null);
                        setSearchDataNumber('');
                        setLoading(false);
                    }

                })
                setTimeout(() => {
                    searchBtn.disabled = false;
                }, 1500);
            }
        }
    }

    const highlightText = (text) => {
        const Search = document.getElementById('Search').value;
        const regex = new RegExp(Search, 'gi');
        const parts = text.split(regex);
        const matches = text.match(regex);

        return parts.map((part, index) => (
            index > 0 ? (
                `<span class="active">${matches[index - 1]}</span>${part}`
            ) : part
        ));
    };

    return (
        <div class="common-content">
            <div class="search-mod">
                <div class="title-text search-text">Search</div>


                <div class="search-blcok-box">
                    <div class="b-search-box">
                        <input type="text" class="form-control form-search" id="Search" placeholder="" autocomplete="off" data-init="done" onKeyDown={handleKeyPress} />
                        <a class="btn-b-search" onClick={SearchClick} id="searchBtn">
                            <img src="/packimpex/images/NL_icons/search.svg" alt="" />
                        </a>

                    </div>
                    {
                        loading ? '' :
                            <div className={styles['search-wrapper']}>
                                {SearchDataNumber ? (
                                    <div className={styles['search-summary-default']}>Showing&nbsp;
                                        <strong>
                                            <span className={styles['user-search-term-num']}>{SearchDataNumber}</span>
                                        </strong> results for <strong>"<span className={styles['user-search-term']}>{SearchText}</span>".</strong>
                                    </div>) :
                                    (
                                        <div className={styles['search-summary-default']}>No Results found for
                                            <strong>"<span className={styles['user-search-term']}>{SearchText}</span>".</strong>
                                        </div>)}
                            </div>
                    }

                </div>

                {
                    loading ? <div className={styles['Loader-div']}><Loader /></div> :
                        SearchData ? (
                            SearchData.map((item) => (
                                // <div key={item.nid}>
                                //     <a href={item.href}>{item.title}</a>
                                //     <p>{item.field_page_description}</p>
                                // </div>
                                <div class="content-txt search-content" key={item.nid}>
                                    <div className="sub-title search-title" dangerouslySetInnerHTML={{ __html: highlightText(item.title).join('') }}></div>
                                    <p dangerouslySetInnerHTML={{ __html: highlightText(item.field_page_description).join('') }}></p>
                                    <div class="cont-txt">
                                        <a href={item.field_page_url} class="search-read-more">
                                            READ MORE
                                        </a>
                                        <br />
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div class="content-txt search-content">
                                <div class="sub-title search-title">Haven't found what you are looking for?</div>
                                <p>Feel free to contact us. We'll happily help you with your request.</p>

                                <div class="cont-txt">
                                    <a href="/" class="search-read-more">
                                        READ MORE
                                    </a>
                                    <br />
                                </div>
                            </div>
                        )}
            </div>
        </div>

    )
};
export default SearchResult;