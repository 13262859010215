import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  menuItems: [],
  topBarActive: false,
  globalLinks: {}
}

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenuItems: (state, action) => {
      state.menuItems = action.payload
    },
    setTopbar: (state, action) => {
      console.log("redux action", action)
      state.topBarActive = action.payload
    },
    setGlobalLinks: (state, action) => {
      state.globalLinks = action.payload
    }
  },
})
// Action creators are generated for each case reducer function
export const { setMenuItems, setTopbar, setGlobalLinks } = menuSlice.actions

export default menuSlice.reducer