export const COUNTRY_DATA_LOCALSTORAGE = "countryData";
export const COUNTRY_DATA_REVIEWSUMMARY = "ReviewSummary";

// 添加数据到localStorage
export const addToLocalStorage = (key, value) => {
    sessionStorage.setItem(key, value);
};

// 从localStorage删除数据
export const removeFromLocalStorage = (key) => {
    sessionStorage.removeItem(key);
};

// 更新localStorage中的数据
export const updateLocalStorage = (key, value) => {
    sessionStorage.setItem(key, value);
};

// 从localStorage中获取数据
export const getFromLocalStorage = (key) => {
    const value = sessionStorage.getItem(key);
    return value ? value : null;
};