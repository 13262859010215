import axios from "axios";
import {
  DRUPAL_API_ENDPOINT,
  FETCH_ALL_URLS,
  FETCH_SPECIFIC_COMPONENT,
  FETCH_MEDIA_FOR_THE_COMPONENT,
  FETCH_MEDIA_DATA,
  IP_API,
  REQUEST_URL,
  COMPANYCODE,
  ENVIRONMENT
} from "../constants/drupalApiEndPoints";
import {
  TAXONOMY_TERM_COUNTRY,
  TAXONOMY_TERM_LANGUAGE,
} from "../constants/taxonomyTerms";
import CustomStorage from "../storage/CustomStorage";
import { setCookie, getCookie, COUNTRY_DATA_COOKIE, HIDE_TOP_BAR_COOKIE, HIDE_TOP_BAR_LOAD_COUNT_COOKIE } from '../utils/cookies';
import { replaceTextLanguage } from '../utils/textUtils';
import { addToLocalStorage, getFromLocalStorage, COUNTRY_DATA_LOCALSTORAGE, COUNTRY_DATA_REVIEWSUMMARY } from '../utils/localStorage';
import { Alert } from "bootstrap";
import { OrderedMap } from 'immutable';
import APIS from '../config/api';

export const getGlobalPageUrls = async (setGlobalPageURL, setLoading) => {
  let pgUrls = [];
  const result = await axios.get(DRUPAL_API_ENDPOINT + FETCH_ALL_URLS);
  let next = result.data.links?.next ? result.data.links.next : null;
  pgUrls.push(result.data.data);
  while (next) {
    const nextUrl = next.href;
    const result2 = await axios.get(nextUrl);
    next = result2.data.links?.next ? result2.data.links?.next : null;
    pgUrls.push(result2.data.data);
  }
  setGlobalPageURL(pgUrls);
  setLoading(false);
};

export const getCurrentRoutePageUrls = async (setGlobalPageURL, setLoading, searchTerm, currentCountryCode) => {
  let pgUrls = [];
  let apiUrl = `${DRUPAL_API_ENDPOINT}${FETCH_ALL_URLS}?filter[field_page_url][value]=${searchTerm}`;
  if (currentCountryCode) {
    apiUrl += '&countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);
  //const result = await axios.get(DRUPAL_API_ENDPOINT + FETCH_ALL_URLS + "?filter[field_page_url][value]=" + searchTerm);
  pgUrls.push(result.data.data);
  setGlobalPageURL(pgUrls);
  setLoading(false);
};

export const getIPBasedPageUrls = async (setIpLocation) => {
  await axios
    .get("https://ipapi.co/json")
    .then((res) => {
      setIpLocation(res);
    })
    .catch((err) => {
      setIpLocation(null);
    });
};
export const getCustomPageUrls = async (
  setPageURL,
  setLoading,
  selectedLanguage,
  selectedCountry
) => {
  let pgUrls = [];
  const result = await axios.get(
    DRUPAL_API_ENDPOINT + "/" + selectedCountry + FETCH_ALL_URLS
  );
  let next = result.data.links?.next ? result.data.links.next : null;
  pgUrls.push(result.data.data);
  while (next) {
    const nextUrl = next.href;
    const result2 = await axios.get(nextUrl);
    next = result2.data.links?.next ? result2.data.links?.next : null;
    pgUrls.push(result2.data.data);
  }
  setPageURL(pgUrls);
  setLoading(false);
};

export const getCustomCurrentRoutePageUrls = async (
  setPageURL,
  setLoading,
  selectedLanguage,
  selectedCountry,
  searchTerm
) => {
  let pgUrls = [];
  const result = await axios.get(DRUPAL_API_ENDPOINT + selectedCountry + FETCH_ALL_URLS + "?filter[field_page_url][value]=" + searchTerm);
  pgUrls.push(result.data.data);
  setPageURL(pgUrls);
  setLoading(false);
};

export const getPageComponents = async (
  pageID,
  setPageData,
  setPageAttributes,
  setPageBreadcrumb,
  setPageBreadcrumbData,
  setLoading,
  resourceVersionId,
  currentCountryCode,
  setNotFound
) => {
  //const result = await axios.get(DRUPAL_API_ENDPOINT + FETCH_ALL_URLS + pageID);
  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_ALL_URLS + pageID;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }

  try {
    const result = await axios.get(apiUrl);

    const breadcrumbReference =
      result.data.data.relationships?.field_breadcrumb_section?.data;
    setPageBreadcrumbData(
      result.data.data.relationships?.field_breadcrumb_section?.data
    );
    if (breadcrumbReference.length > 0) {
      setPageBreadcrumb(true);
    }
    setPageData(result.data.data.relationships.field_web_page_sections);
    setPageAttributes(result.data.data.attributes);
    setLoading(false);
    return result;
  } catch (error) {
    setNotFound(true);
  }
};

export const getBreadcrumbData = async (id, setPageBreadcrumb, setLoading, resourceVersionId, currentCountryCode) => {
  // const breadcrumbData = resourceVersionId
  //   ? await axios.get(DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + "breadcrumb/" + id + '?resourceVersion=id%3A' + resourceVersionId)
  //   : await axios.get(DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + "breadcrumb/" + id);
  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + "breadcrumb/" + id;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const breadcrumbData = await axios.get(apiUrl);

  setPageBreadcrumb(breadcrumbData.data.data.attributes);
  setLoading(false);
};

export const getBannerComponentData = async (
  setBannerData,
  setImage,
  setImageAlt,
  setSvg,
  componentType,
  componentID,
  setLoading,
  resourceVersionId,
  currentCountryCode
) => {
  // const result = await axios.get(
  //   DRUPAL_API_ENDPOINT +
  //   FETCH_SPECIFIC_COMPONENT +
  //   componentType +
  //   "/" +
  //   componentID
  // );
  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + componentType + "/" + componentID;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);
  setBannerData(result.data.data.attributes.field_banner_title);
  setSvg(result.data.data.attributes.field_svg_code_component?.value);
  const mediaID = result.data.data.relationships?.field_banner_image?.data?.id;
  let mediaFileID = null;
  if (mediaID) {
    const mediaResult = await axios.get(
      DRUPAL_API_ENDPOINT + FETCH_MEDIA_DATA + mediaID
    );
    setImageAlt(mediaResult.data.data.relationships.thumbnail.data.meta.alt);
    mediaFileID = mediaResult.data.data.relationships?.thumbnail?.data?.id;
  }
  if (mediaFileID) {
    const mediaData = await axios.get(
      DRUPAL_API_ENDPOINT + FETCH_MEDIA_FOR_THE_COMPONENT + mediaFileID
    );
    setImage(DRUPAL_API_ENDPOINT + mediaData.data.data.attributes.uri.url);
  }
  setLoading(false);
};

export const getBannerWithTitleDescButtonData = async (
  setData,
  setBtnTitle,
  setBtnUrl,
  setSvg,
  setImageAlt,
  setImage,
  setCardsData,
  componentType,
  componentID,
  setLoading,
  setOpenType,
  resourceVersionId,
  currentCountryCode
) => {
  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + componentType + "/" + componentID;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);

  setData(result.data);
  setCardsData(result.data.data.relationships.field_cards.data);
  setBtnTitle(result.data.data.attributes.field_content_button?.title);
  let buttonUrl = result.data.data.attributes.field_content_button?.uri?.replace(
    "internal:",
    ""
  );
  let openType = result.data.data.attributes.field_url_open_type
  setOpenType(openType)
  setBtnUrl(buttonUrl);
  setSvg(result.data.data.attributes.field_svg_code_component?.value);
  const mediaID = result.data.data.relationships?.field_content_image?.data?.id;
  let mediaFileID = null;
  if (mediaID) {
    const mediaResult = await axios.get(
      DRUPAL_API_ENDPOINT + FETCH_MEDIA_DATA + mediaID
    );
    setImageAlt(
      mediaResult.data.data.relationships?.thumbnail?.data?.meta?.alt
    );
    mediaFileID = mediaResult.data.data.relationships?.thumbnail?.data?.id;
  }
  if (mediaFileID) {
    const mediaData = await axios.get(
      DRUPAL_API_ENDPOINT + FETCH_MEDIA_FOR_THE_COMPONENT + mediaFileID
    );
    setImage(DRUPAL_API_ENDPOINT + mediaData.data.data.attributes.uri.url);
  }
  setLoading(false);
};

export const getImageTextLeftRightData = async (
  setYear,
  setTitle,
  setDescription,
  setPosition,
  setImage,
  setImageAlt,
  setLink,
  setSvg,
  componentType,
  componentID,
  setLoading,
  setTitlePosition,
  resourceVersionId,
  currentCountryCode
) => {
  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + componentType + "/" + componentID;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);
  setTitlePosition(result.data.data.attributes.field_title_position);
  setYear(result.data.data.attributes.field_year);
  setTitle(result.data.data.attributes.field_title_2);
  setDescription(result.data.data.attributes.field_description_2?.value);
  if (result.data.data.attributes.field_cta_button) {
    setLink(result.data.data.attributes.field_cta_button.title);
  }
  setPosition(result.data.data.attributes.field_image_position);
  setSvg(result.data.data.attributes.field_svg_code_component?.value);
  const mediaID = result.data.data.relationships?.field_image_2?.data?.id;
  let mediaFileID = null;
  if (mediaID) {
    const mediaResult = await axios.get(
      DRUPAL_API_ENDPOINT + FETCH_MEDIA_DATA + mediaID
    );
    setImageAlt(mediaResult.data.data.relationships.thumbnail?.data?.meta?.alt);
    mediaFileID = mediaResult.data.data.relationships?.thumbnail?.data?.id;
  }
  if (mediaFileID) {
    const mediaData = await axios.get(
      DRUPAL_API_ENDPOINT + FETCH_MEDIA_FOR_THE_COMPONENT + mediaFileID
    );
    setImage(DRUPAL_API_ENDPOINT + mediaData.data.data.attributes?.uri?.url);
  }
  setLoading(false);
};

export const getTitleDescriptionData = async (
  setTitle,
  setDescription,
  setSvg,
  componentType,
  componentID,
  setLoading,
  setTitlePosition,
  resourceVersionId,
  currentCountryCode
) => {
  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + componentType + "/" + componentID;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);
  setTitlePosition(result.data.data.attributes.field_title_position);
  setTitle(result.data.data.attributes.field_component_title);
  setDescription(result.data.data.attributes.field_component_description);
  setSvg(result.data.data.attributes.field_svg_code_component?.value);
  setLoading(false);
};

export const getHtmlEditorData = async (
  setEditor,
  componentType,
  componentID,
  setLoading,
  resourceVersionId,
  currentCountryCode
) => {
  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + componentType + "/" + componentID;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);

  const htmlCode = result.data.data.attributes.field_html_code ? result.data.data.attributes.field_html_code.value : '';
  setEditor(replaceTextLanguage(currentCountryCode, htmlCode, 'html'));
  if (htmlCode) {
    setLoading(false);
  }

};

export const getCardSliderParentData = async (
  setDataLenght,
  setCardSliderData,
  componentType,
  componentID,
  setLoading
) => {
  const result = await axios.get(
    DRUPAL_API_ENDPOINT +
    FETCH_SPECIFIC_COMPONENT +
    componentType +
    "/" +
    componentID
  );
  setDataLenght(result.data.data.attributes.field_component_title);
  setCardSliderData(
    result.data.data.relationships?.field_card_components?.data
  );
  setLoading(false);
};

export const getCardSliderData = async (
  setFieldTitle,
  setBtnUrl,
  setBtnTitle,
  setImgUrl,
  componentType,
  componentID,
  setLoading,
  setOpenType,
  setTitlePosition,
  currentCountryCode,
  resourceVersionId
) => {
  // const result = await axios.get(
  //   DRUPAL_API_ENDPOINT +
  //   FETCH_SPECIFIC_COMPONENT +
  //   componentType +
  //   "/" +
  //   componentID
  // );
  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + componentType + "/" + componentID;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);
  setTitlePosition(result.data.data.attributes.field_title_position);
  setFieldTitle(result.data.data.attributes?.field_title);
  let buttonUrl = result.data.data.attributes?.field_button?.uri?.replace(
    "internal:",
    ""
  );
  let openType = result.data.data.attributes.field_url_open_type
  setOpenType(openType)
  setBtnUrl(buttonUrl);
  setBtnTitle(result.data.data.attributes?.field_button?.title);
  const imgApi =
    result.data.data.relationships?.field_image?.links?.related?.href;
  if (imgApi) {
    const resultImg = await axios.get(imgApi);

    const mediaData = await axios.get(
      resultImg.data.data?.relationships?.field_media_image?.links?.related?.href
    );
    setImgUrl(DRUPAL_API_ENDPOINT + mediaData.data.data.attributes.uri.url);


  }
  setLoading(false);
};

export const getCardSliderWithTitleData = async (
  setFieldAnchorId,
  setHeaderData,
  setCardSliderData,
  setSvg,
  componentType,
  componentID,
  setLoading,
  setTitlePosition,
  resourceVersionId,
  currentCountryCode
) => {
  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + componentType + "/" + componentID;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);
  setFieldAnchorId(result.data.data.attributes?.field_anchor_id?.replace('#', '') ?? null)
  setTitlePosition(result.data.data.attributes.field_title_position);
  setHeaderData(result.data.data.attributes?.field_heading);
  setCardSliderData(
    result.data.data.relationships?.field_card_components?.data
  );
  setSvg(result.data.data.attributes?.field_svg_code_component?.value);
  setLoading(false);
};

// export const getUserCountryData = async (setUserCountry, setOtherMessage) => {
//   let index = -1;
//   let languageArray = [];
//   const result = await axios.get(IP_API)
//     .then()
//     .catch(() => {
//       setOtherMessage("Other Country Website");
//       return;
//     });
//   const userCountry = result.data?.country_name;
//   //const userCountry = "Hong Kong";
//   console.log(result.data?.country_name);
//   const result2 = await axios.get(DRUPAL_API_ENDPOINT + "/" + TAXONOMY_TERM_COUNTRY);
//   index = result2.data?.data?.findIndex((item) => item.attributes.name.match(userCountry));
//   if (index !== -1) {
//     setOtherMessage(result2.data.data[index].attributes?.field_other_message);
//     const languagesData = result2.data.data[index].relationships.field_country_languages.data;
//     const promises = languagesData.map(async (data) => {
//       const result3 = await axios.get(DRUPAL_API_ENDPOINT + "/" + TAXONOMY_TERM_LANGUAGE + "/" + data.id);
//       let languageName = result3.data.data.attributes.field_language_name_footer;
//       let languageCode = result3.data.data.attributes.field_language_code;
//       let languageURL = result3.data.data.attributes.field_language_url;
//       languageArray.push({ languageName, languageCode, languageURL });
//     });
//     const languageMap = await Promise.all(promises);
//     if (languageMap) {
//       setUserCountry(languageArray);
//     }
//   }
//   else {
//     index = result2.data.data.findIndex((item) => item.attributes.name === 'Global');
//     if (index !== -1) {
//       setOtherMessage(result2.data.data[index].attributes?.field_other_message);
//     }
//     else {
//       setOtherMessage("Other Country Website");
//     }
//   }
// };


export const getCountryDataLocalStorage = async () => {
  let languageArray = [];
  const CountryDataLocalStorage = getFromLocalStorage(COUNTRY_DATA_LOCALSTORAGE);

  if (!CountryDataLocalStorage) {

    const result = await axios.get(DRUPAL_API_ENDPOINT + "/" + TAXONOMY_TERM_LANGUAGE);
    const languagesData = result.data.data;
    languagesData.forEach((data) => {
      let languageName = data.attributes.field_language_name_footer;
      let languageCode = '';
      let languageURL = data.attributes.field_language_url.replace('/home', '');
      let languageID = data.id;
      let countryFooter = data.attributes.field_country_name_footer_;

      if (languageURL !== '/') {
        languageArray.push({ languageName, languageCode, languageURL, languageID, countryFooter });
      }

    });

    const result2 = await axios.get(DRUPAL_API_ENDPOINT + "/" + TAXONOMY_TERM_COUNTRY);
    const countryData = result2.data.data;
    countryData?.forEach((data) => {
      let languageCode = data.attributes.name;
      let field_other_message = data.attributes.field_other_message;
      let index = 0;
      const countryLanguageData = data.relationships.field_country_languages.data;
      countryLanguageData.forEach((data) => {
        languageArray.filter(language => language.languageID === data.id)
          .forEach(language => {
            language.index = index;
            language.languageCode = languageCode;
            language.field_other_message = field_other_message;
            index++;
          });
      });
    });
    addToLocalStorage(COUNTRY_DATA_LOCALSTORAGE, JSON.stringify(languageArray));

    return JSON.stringify(languageArray);
  } else {
    return CountryDataLocalStorage;
  }

};


export const getUserCountryData = async (setUserCountry, setOtherMessage, page) => {

  const CountryData = await getCountryDataLocalStorage()
    .then()
    .catch(() => {
      setOtherMessage("Other country or region");
      return;
    });
  let languageCode = null;
  let countryFooter = null;
  let field_other_message = null;
  if (CountryData) {
    for (const language of JSON.parse(CountryData)) {
      if (language.languageURL === '/' + page) {
        languageCode = language.languageCode;
        countryFooter = language.countryFooter;
        field_other_message = language.field_other_message;
        break;
      }
    }

    const filteredData = JSON.parse(CountryData)?.filter(item => item.languageCode === languageCode && languageCode !== "").sort((a, b) => a.index - b.index);
    filteredData.forEach(language => {
      language.countryFooter = countryFooter;
    });
    if (Object.keys(filteredData).length > 0) {
      setUserCountry(filteredData);
    }
    if (field_other_message) {
      setOtherMessage(field_other_message);
    } else {
      setOtherMessage("Other country or region");
    }
  }

};

export const getLoadCount = async (page, setIsVisible) => {
  const CountryData = await getCountryDataLocalStorage()
    .then()
    .catch(() => {
      return;
    });

  const filteredData = JSON.parse(CountryData)?.filter(item => item.languageURL === '/' + page);
  if (filteredData.length > 0) {
    if (!getCookie(HIDE_TOP_BAR_COOKIE)) {
      let loadCount = getCookie(HIDE_TOP_BAR_LOAD_COUNT_COOKIE);
      if (loadCount >= 2) {
        setCookie(HIDE_TOP_BAR_COOKIE, 'true', 30);
        setIsVisible(false);
      } else {
        setCookie(HIDE_TOP_BAR_LOAD_COUNT_COOKIE, ++loadCount);
      }
    }
  }
}

export const getUserIPData = async (setButtonMessage, setCountryMessage, setOtherMessage, setUserOtherLanguage, setSelectedValue, setLoading, setIsVisible, page) => {
  let index = -1;
  let languageArray = [];
  let selectedLanguage = CustomStorage.get('selectedLanguage');

  const result = await axios.get(IP_API)
    .then()
    .catch(() => {
      CustomStorage.set('selectedLanguage', "other");
      setSelectedValue("other");
      setButtonMessage('Continue');
      setOtherMessage("Other country or region");
      setCountryMessage('Choose another country or region to see content specific to your location');
      setUserOtherLanguage('');
      setLoading(true);
      return;
    });
  //const userCountry = "Hong Kong";
  //const userCountryCode = "HK";
  const userCountry = result?.data && result?.data.country_name ? result?.data.country_name : null;
  const userCountryCode = result?.data && result?.data.country_code ? result?.data.country_code : null;

  //IP encountered a situation where it was unable to obtain "country_name", returned null, hidden
  if (!userCountry) {
    setCookie(HIDE_TOP_BAR_COOKIE, 'true', 30);
    setIsVisible(false);
    setLoading(false);
  }
  const result2 = await axios.get(DRUPAL_API_ENDPOINT + "/" + TAXONOMY_TERM_COUNTRY);
  index = result2.data.data.findIndex(item =>
    item.attributes.status === true &&
    item.attributes.name === userCountry
  );
  //index = result2.data.data.findIndex((item) => item.attributes.name.match(userCountry));
  if (index !== -1) {
    setButtonMessage(result2.data.data[index].attributes?.field_button_message);
    setCountryMessage(result2.data.data[index].attributes?.field_country_message);
    setOtherMessage(result2.data.data[index].attributes?.field_other_message);
    const languagesData = result2.data.data[index].relationships.field_country_languages.data;
    for (const [index, data] of languagesData.entries()) {
      const result3 = await axios.get(`${DRUPAL_API_ENDPOINT}/${TAXONOMY_TERM_LANGUAGE}/${data.id}`);
      let languageName = result3.data.data.attributes.field_language_name_footer;
      let languageCode = result3.data.data.attributes.field_language_code;
      let languageURL = result3.data.data.attributes.field_language_url;
      if (!Boolean(selectedLanguage) && userCountryCode.toLowerCase() === languageCode.toLowerCase()) {
        setSelectedValue(languageURL);
        CustomStorage.set('selectedLanguage', languageURL);
      }
      languageArray.push({ languageName, languageCode, languageURL });
    }

    //When there is only one language data, it is not necessary to display it
    if (languageArray.length <= 1) {
      setIsVisible(false);
      setCookie(HIDE_TOP_BAR_COOKIE, 'true', 30);
    } else {

      setUserOtherLanguage(languageArray);

    }
    setLoading(false);

  }
  else {

    setCookie(HIDE_TOP_BAR_COOKIE, 'true', 30);

    index = result2.data.data.findIndex((item) => item.attributes.name === 'Global');
    if (index !== -1) {
      setButtonMessage(result2.data.data[index].attributes?.field_button_message);
      setCountryMessage(result2.data.data[index].attributes?.field_country_message);
      setOtherMessage(result2.data.data[index].attributes?.field_other_message);
      setSelectedValue("other");
      CustomStorage.set('selectedLanguage', "other");
    }
    setIsVisible(false);
    setLoading(false);

  }

  //
  // const CountryData = await getCountryDataCookie()
  //   .then()
  //   .catch(() => {
  //     setOtherMessage("Other Country Website");
  //     return;
  //   });

};

export const getCountryData = async (setCountryData, setLoading) => {
  const result = await axios.get(
    DRUPAL_API_ENDPOINT + "/" + TAXONOMY_TERM_COUNTRY
  );
  setCountryData(result.data.data);
  setLoading(false);
};

export const getLanguageData = async (
  setLanguageName,
  setLanguageUrl,
  setLoading,
  languageID
) => {
  const result = await axios.get(
    DRUPAL_API_ENDPOINT + "/" + TAXONOMY_TERM_LANGUAGE + "/" + languageID
  );
  if (result.data.data.attributes.status) {
    setLanguageName(result.data.data.attributes.field_language_name);
    setLanguageUrl(result.data.data.attributes.field_language_url);
  }

  setLoading(false);
};

export const getCountryFlagData = async (imageID, setImgUrl, setLoading) => {
  const result = await axios.get(
    DRUPAL_API_ENDPOINT + FETCH_MEDIA_FOR_THE_COMPONENT + imageID
  );
  setImgUrl(DRUPAL_API_ENDPOINT + result.data.data.attributes.uri.url);
  setLoading(false);
};

export const getZigzagTriCardData = async (
  setHeaderData,
  setZigzagTriCardData,
  setSvg,
  componentType,
  componentID,
  setLoading,
  setTitlePosition,
  resourceVersionId,
  currentCountryCode
) => {
  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + componentType + "/" + componentID;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);
  setTitlePosition(result.data.data.attributes.field_title_position);
  setHeaderData(result.data.data.attributes.field_title_3);
  setZigzagTriCardData(
    result.data.data.relationships?.field_card_components_2?.data
  );
  setSvg(result.data.data.attributes.field_svg_code_component?.value);
  setLoading(false);
};

export const getZigzagHexaCardsData = async (
  setHeadlineData,
  setTitleData,
  setTitlePosition,
  setZigzagHexaCardData,
  setSvg,
  componentType,
  componentID,
  setLoading,
  setKickerPosition,
  resourceVersionId,
  currentCountryCode
) => {
  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + componentType + "/" + componentID;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);
  setKickerPosition(result.data.data.attributes.field_kicker_heading_position)
  setHeadlineData(result.data.data.attributes?.field_kicker_headline_1);
  setZigzagHexaCardData(
    result.data.data.relationships?.field_card_components_1?.data
  );
  setTitleData(result.data.data.attributes?.field_title_1);
  setSvg(result.data.data.attributes?.field_svg_code_component?.value);
  setLoading(false);
  setTitlePosition(result.data.data.attributes.field_title_position);
};



export const getZigzagCardsData = async (
  setFieldTitle,
  setFieldDescription,
  setBtnUrl,
  setBtnTitle,
  setImgUrl,
  componentType,
  componentID,
  setLoading,
  setOpenType,
  setTitlePosition,
  resourceVersionId,
  currentCountryCode
) => {
  // const result = await axios.get(
  //   DRUPAL_API_ENDPOINT +
  //   FETCH_SPECIFIC_COMPONENT +
  //   componentType +
  //   "/" +
  //   componentID
  // );
  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + componentType + "/" + componentID;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);
  setTitlePosition(result.data.data.attributes.field_title_position);
  setFieldTitle(result.data.data.attributes?.field_card_title);
  setFieldDescription(
    result.data.data.attributes?.field_card_description?.value
  );
  let buttonUrl = result.data.data.attributes?.field_card_button?.uri?.replace("internal:", '');
  let openType = result.data.data.attributes.field_url_open_type
  setOpenType(openType)
  setBtnUrl(buttonUrl);
  setBtnTitle(result.data.data.attributes?.field_card_button?.title);
  const imgApi =
    result.data.data.relationships?.field_card_image?.links?.related?.href;
  if (imgApi) {
    const resultImg = await axios.get(imgApi);

    const mediaData = await axios.get(
      resultImg.data.data?.relationships?.field_media_image?.links?.related?.href
    );
    setImgUrl(DRUPAL_API_ENDPOINT + mediaData.data.data.attributes.uri.url);

  }
  setLoading(false);
};

export const getZigzagTriCardSingleData = async (
  setFieldTitle,
  setFieldDescription,
  setBtnUrl,
  setBtnTitle,
  setImgUrl,
  componentType,
  componentID,
  setLoading,
  setOpenType,
  setTitlePosition,
  currentCountryCode
) => {
  // const result = await axios.get(
  //   DRUPAL_API_ENDPOINT +
  //   FETCH_SPECIFIC_COMPONENT +
  //   componentType +
  //   "/" +
  //   componentID
  // );
  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + componentType + "/" + componentID;
  let hasParams = false;
  // if (resourceVersionId) {
  //   apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
  //   hasParams = true;
  // }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);
  setTitlePosition(result.data.data.attributes.field_title_position);
  setFieldTitle(result.data.data.attributes?.field_card_title);
  setFieldDescription(result.data.data.attributes?.field_card_description?.value);
  let buttonUrl = result.data.data.attributes?.field_card_button?.uri?.replace("internal:", '');
  setBtnUrl(buttonUrl);
  let openType = result.data.data.attributes.field_url_open_type
  setOpenType(openType)
  setBtnTitle(result.data.data.attributes?.field_card_button?.title);
  const imgApi =
    result.data.data.relationships?.field_card_image?.links?.related?.href;
  if (imgApi) {
    const resultImg = await axios.get(imgApi);

    const mediaData = await axios.get(
      resultImg.data.data?.relationships?.field_media_image?.links?.related?.href
    );
    setImgUrl(DRUPAL_API_ENDPOINT + mediaData.data.data.attributes.uri.url);

  }
  setLoading(false);
};

export const getSpacerData = async (
  setPageData,
  componentType,
  componentID,
  setLoading,
  resourceVersionId,
  currentCountryCode
) => {
  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + componentType + "/" + componentID;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);
  setPageData(result.data?.data?.attributes)
  setLoading(false);
};

export const getC1H1Data = async (
  setPageData,
  componentType,
  componentID,
  setLoading,
  resourceVersionId,
  currentCountryCode
) => {
  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + componentType + "/" + componentID;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);
  setPageData(result.data?.data?.attributes)
  setLoading(false);
};

export const getSocialMedia = async (
  setPageData,
  componentType,
  componentID,
  setLoading
) => {
  const result = await axios.get(
    DRUPAL_API_ENDPOINT +
    FETCH_SPECIFIC_COMPONENT +
    componentType +
    "/" +
    componentID
  );
  setPageData(result.data?.data)
  setLoading(false);
};

export const getReviewSummary = async (
  setReviewSummary
) => {
  const CountryDataReviewSummary = getFromLocalStorage(COUNTRY_DATA_REVIEWSUMMARY);

  if (!CountryDataReviewSummary) {
    const requestData = {};
    axios.post(`${REQUEST_URL}/review/review-summary`, requestData, {
      params: {
        companycode: `${COMPANYCODE}`,
        environment: `${ENVIRONMENT}`
      },
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.data?.Data) {
          const { AverageQty, SummaryQty } = response.data.Data;

          if (AverageQty) {
            response.data.Data.Average = AverageQty;
          }
          if (SummaryQty) {
            response.data.Data.Summary = SummaryQty;
          }
        }
        setReviewSummary(response.data?.Data);
        addToLocalStorage(COUNTRY_DATA_REVIEWSUMMARY, JSON.stringify(response.data?.Data));
      })
      .catch(error => {
        console.error(error);
      });
  } else {
    setReviewSummary(JSON.parse(CountryDataReviewSummary));
  }

};
export const getReviewRating = async (
  setReviewRating
) => {
  const requestData = {};
  axios.post(`${REQUEST_URL}/review/review-rating`, requestData, {
    params: {
      companycode: `${COMPANYCODE}`,
      environment: `${ENVIRONMENT}`
    },
    headers: {
      'accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      setReviewRating(response.data?.Data);
    })
    .catch(error => {
      console.error(error);
    });
};
export const getReviewList = async (
  setReviewData,
  Page,
  Size,
  Rating,
  RatingSorting,
  ReviewDate,
  setPageNumber,
  setLoading,
  Location,
  isDescription
) => {
  const requestData = {};

  if (Rating !== '') {
    requestData.rating = Rating;
  }
  if (Location !== '') {
    requestData.Location = Location;
  }
  if (isDescription === "true") {
    requestData.isDescription = true;
  }

  axios.post(`${REQUEST_URL}/review/new-list`, requestData, {
    params: {
      companycode: `${COMPANYCODE}`,
      environment: `${ENVIRONMENT}`,
      size: Size,
      page: Page,
      // rating: RatingSorting,
      Review_Date: ReviewDate
    },
    headers: {
      'accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      setReviewData(response.data?.Data.result);
      setPageNumber(response.data?.Data.pages);
      setLoading(false);
    })
    .catch(error => {
      console.error(error);
    });
};


export const getC16VideoCard = async (
  componentType,
  componentID,
  setPageVideoCardData,
  resourceVersionId,
  currentCountryCode
) => {
  // const result = await axios.get(
  //   DRUPAL_API_ENDPOINT +
  //   FETCH_SPECIFIC_COMPONENT +
  //   componentType +
  //   "/" +
  //   componentID
  // );

  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + componentType + "/" + componentID;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);
  setPageVideoCardData(result.data.data.relationships?.field_video_cards?.data)
};

export const getC16Video = async (
  componentType,
  componentID,
  setPageVideoData,
  setImgUrl,
  setBtnUrl,
  resourceVersionId,
  currentCountryCode
) => {
  // const result = await axios.get(
  //   DRUPAL_API_ENDPOINT +
  //   FETCH_SPECIFIC_COMPONENT +
  //   componentType +
  //   "/" +
  //   componentID
  // );
  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + componentType + "/" + componentID;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);
  setPageVideoData(result.data.data.attributes)
  let buttonUrl = result.data.data.attributes?.field_card_button?.uri?.replace("internal:", '');
  setBtnUrl(buttonUrl);

  const imgApi =
    result.data.data.relationships?.field_card_image?.links?.related?.href;
  if (imgApi) {
    const resultImg = await axios.get(imgApi);

    const mediaData = await axios.get(
      resultImg.data.data?.relationships?.field_media_image?.links?.related?.href
    );
    setImgUrl(DRUPAL_API_ENDPOINT + mediaData.data.data.attributes.uri.url);

  }

};



export const getC17Video = async (
  componentType,
  componentID,
  setC17Video,
  setImgUrl,
  resourceVersionId,
  currentCountryCode
) => {
  // const result = await axios.get(
  //   DRUPAL_API_ENDPOINT +
  //   FETCH_SPECIFIC_COMPONENT +
  //   componentType +
  //   "/" +
  //   componentID
  // );
  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + componentType + "/" + componentID;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);
  setC17Video(result.data.data.attributes)

  const imgApi =
    result.data.data.relationships?.field_image?.links?.related?.href;
  if (imgApi) {
    const resultImg = await axios.get(imgApi);

    const mediaData = await axios.get(
      resultImg.data.data?.relationships?.field_media_image?.links?.related?.href
    );
    setImgUrl(DRUPAL_API_ENDPOINT + mediaData.data.data.attributes.uri.url);

  }

};

export const getReviewSchemaInfo = async (
  page,
  setServiceType,
  setAreaServed
) => {
  const URL = "/" + page.split('/')[1]; // 待匹配的URL变量
  const CountryData = await getCountryDataLocalStorage();

  const matchedLanguage = JSON.parse(CountryData).find(obj => obj.languageURL === URL); // 匹配languageURL
  if (matchedLanguage) {
    setAreaServed(matchedLanguage.languageCode);
  } else {
    setAreaServed("Europe");
  }

  // if (page.includes("arrival")) {
  //   setServiceType("Arrival Service"); // URL包含"arrival"，返回"Arrival Service"
  // } else if (page.includes("departure")) {
  //   setServiceType("Departure Service"); // URL包含"departure"，返回"Departure Service"
  // } else {
  //   setServiceType("Relocation Service"); // URL不包含"arrival"和"departure"，返回"Relocation Service"
  // }
  setServiceType("Relocation Service");
};
export const getPageLanguages = async (
  page,
  setLanguages
) => {
  let url = page.split('/');
  let field_language_url = url[1];
  let apiUrl = `${DRUPAL_API_ENDPOINT}/${TAXONOMY_TERM_LANGUAGE}?filter[field_language_url][value]=/${field_language_url}`;
  const result = await axios.get(apiUrl);
  const languagesData = result.data.data;

  if (languagesData.length > 0) {
    setLanguages(field_language_url);
  } else {
    setLanguages('');
  }
};


export const getC18PointListsData = async (
  componentType,
  componentID,
  setC18PointListsData,
  resourceVersionId,
  currentCountryCode
) => {

  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + componentType + "/" + componentID;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);

  setC18PointListsData(result.data.data);
};


export const getC18PointData = async (
  componentID,
  setC18PointData,
  resourceVersionId,
  currentCountryCode
) => {

  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + "point_lists/" + componentID;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);

  setC18PointData(result.data.data.attributes);
};

export const getC19TitleListsData = async (
  componentType,
  componentID,
  setC19TitleListsData,
  resourceVersionId,
  currentCountryCode
) => {
  let apiUrl = DRUPAL_API_ENDPOINT + FETCH_SPECIFIC_COMPONENT + componentType + "/" + componentID;
  let hasParams = false;
  if (resourceVersionId) {
    apiUrl += '?resourceVersion=id%3A' + resourceVersionId;
    hasParams = true;
  }
  if (currentCountryCode) {
    apiUrl += (hasParams ? '&' : '?') + 'countryLangPrefix=' + currentCountryCode;
  }
  const result = await axios.get(apiUrl);

  setC19TitleListsData(result.data.data);
};