import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { DRUPAL_API_ENDPOINT } from "./constants/drupalApiEndPoints";
import Pages from "./pages/Pages";


function App() {
  window.tryToLoadFromSite = (item) => {
    if (item.src.includes(DRUPAL_API_ENDPOINT) === false) {
      let origSrc = item.src;
      if (origSrc.includes(window.location.origin)) {
        origSrc = origSrc.replace(window.location.origin, "");
      }
      item.src = DRUPAL_API_ENDPOINT + "/" + origSrc;
    }
  };
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="*" exact element={<Pages />} />
      </Routes>
    </Router>
  );


}

export default App;
