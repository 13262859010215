
import React, { useState, useEffect } from "react";
import styles from "../BannerWithTitleDescButton/BannerWithTitleDescButton.module.css";
import CardSlider from "../CardSlider/CardSlider";
import { getBannerWithTitleDescButtonData } from "../../api/getData";
import { CONTENT_COMPONENT_WITH_IMAGE_TITLE } from "../../constants/componentTypes";
import TriCards from "./TriCards";
import { replaceTextLanguage, replaceTextGreenArrowIcon } from '../../utils/textUtils';

const BannerWithTitleDescButton = ({ componentID, firstComponent, resourceVersionId, currentCountryCode }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [cardsData, setCardsData] = useState(null);
  const [btnUrl, setBtnUrl] = useState(null);
  const [btnTitle, setBtnTitle] = useState(null);
  const [imageAlt, setImageAlt] = useState(null);
  const [image, setImage] = useState(null);
  const [svg, setSvg] = useState(null);
  const [openType, setOpenType] = useState(null);

  useEffect(() => {
    getBannerWithTitleDescButtonData(setData, setBtnTitle, setBtnUrl, setSvg, setImageAlt, setImage, setCardsData, CONTENT_COMPONENT_WITH_IMAGE_TITLE, componentID, setLoading, setOpenType, resourceVersionId, currentCountryCode);
  }, [componentID, currentCountryCode, resourceVersionId]);
  const addWidthToImgTags = (html='', width) => {
    if (window.innerWidth < 1024) {
      width = "100%";
    }
    if (width === null) {
      width = "75%";
    }
    return html.replace(/<img([^>]*)>/g, (match, p1) => {
      // Check if the <img> tag contains the class cont-item-svg
      const hasContItemSvg = /class\s*=\s*(['"])[^'"]*\bcont-item-svg\b[^'"]*\1/.test(p1);

      // If the <img> tag contains cont-item-svg class, keep it unchanged
      if (hasContItemSvg) {
        return match;
      }
      const style = `width: ${width};`;
      return `<img${p1} style="${style}">`;
    });
  };
  return (
    <>
      {loading ? null : (
        <>
          <div className={`${firstComponent ? styles.banner__area_bottom_p50 : styles.banner__area_p80}`} >
            <div className="container">
              <div className={`row`}>
                <div className={`col-md-12 ${styles.banner__container}`} style={{ width: window.innerWidth < 1024 ? '100%' : (data.data.attributes.field_component_width || '100%') }}>
                  {image !== null && (
                    <div className={`${styles.img__container} img-fluid`}>
                      <picture>
                        <source media="(max-width: 767px)" srcset={image.replace("sites/default/files", "sites/default/files/styles/large/public")} />
                        <img src={image} alt={imageAlt} width="1600" height="1000" onError={(e) => {
                          if (!e.target.dataset.error) {
                            e.target.dataset.error = 'true';
                            const source = e.target.parentElement.querySelector('source');
                            source.srcset = image;
                          }
                        }} />
                      </picture>
                    </div>
                  )}
                  <div className="col-md-12 p-0" >
                    {
                      data.data.attributes.field_kicker_headline && (
                        <span className={`${data.data.attributes.field_kicker_heading_position ? `title-position-${data.data.attributes.field_kicker_heading_position}` : ''}`}>{data.data.attributes.field_kicker_headline}</span>
                      )
                    }
                    {
                      data.data.attributes.field_content_title && (
                        <h2 className={`${data.data.attributes.field_title_position ? `title-position-${data.data.attributes.field_title_position}` : ''}`} id={data?.data?.attributes?.field_anchor_id?.replace('#', '') ?? null}>{data.data.attributes.field_content_title}</h2>
                      )
                    }


                    <div className={` ${styles.banner__content}`}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: replaceTextGreenArrowIcon(replaceTextLanguage(currentCountryCode, addWidthToImgTags(data.data.attributes.field_description?.processed, data.data.attributes.field_image_width), 'html')),
                        }}
                      ></p>

                      {btnTitle && (
                        <a href={replaceTextLanguage(currentCountryCode, btnUrl)} target={openType}>
                          <button className={`btn__green`} style={{ margin: data.data.attributes.field_description?.processed ? '1.8rem 0' : '0' }}>
                            {btnTitle}
                          </button>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {cardsData && cardsData.length > 0 &&
            <TriCards cardsData={cardsData} currentCountryCode={currentCountryCode} />
          }

          {(svg !== null && svg !== '' && svg !== undefined) && (
            <>
              <div
                className={styles.svg__block__1__up}
                dangerouslySetInnerHTML={{
                  __html: svg,
                }}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
export default BannerWithTitleDescButton;
