import React, { useState, useEffect } from "react";
import styles from "../C19_TitleLists/C19_TitleLists.module.css";
import { getC19TitleListsData } from "../../api/getData";
import { COMPONENT_C19_TITLE_LISTS_COMPONENT } from "../../constants/componentTypes";
import Title from './Title';


export default function C19_TitleLists({ componentID, resourceVersionId, currentCountryCode }) {
  const [C19TitleListsData, setC19TitleListsData] = useState(null);

  useEffect(() => {
    getC19TitleListsData(COMPONENT_C19_TITLE_LISTS_COMPONENT, componentID, setC19TitleListsData, resourceVersionId, currentCountryCode);
  }, [componentID, currentCountryCode, resourceVersionId]);

  return (
    <>
      {
        C19TitleListsData && (
          <>
            <div className="container">
              <div className={`row`}>
                <div className={`col-md-12 ${styles.banner__container}`}>
                  <div className="col-md-12 p-0">
                    <div className={` ${styles.banner__content}`}>
                      <p>
                        <div className={`${styles.ebcContent}`}>
                          {C19TitleListsData.relationships.field_title_list &&
                            C19TitleListsData.relationships.field_title_list.data.map((data, index) => (

                              <Title componentID={data.id} resourceVersionId={data.meta.target_revision_id} currentCountryCode={currentCountryCode} />

                            ))}
                        </div>
                      </p>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
    </>
  );
};
