import React from "react";
import { useState, useEffect, useRef } from "react";
import Menu from "../Header/Menu";
import Footer from "../Footer/Footer";
import PackImpex from "./PackImpex";
import SocialMedia from "../Footer/SocialMedia";
import axios from "axios";
import APIS from '../../config/api'
import {
  DRUPAL_API_ENDPOINT
} from "../../constants/drupalApiEndPoints";
import { useDispatch } from 'react-redux';
import { setGlobalLinks } from "../../store/reducer/menu";

const Layout = (props) => {
  const [footer, setFooter] = useState(false);
  const dispatch = useDispatch();
  const [hasScrolled, setHasScrolled] = useState(false);
  const isFirstClick = useRef(true);
  const loadDynamicScript = () => {
    axios.get(APIS.DYNAMIC_SCRIPT).then((res) => {
      const data = res.data.data[0]?.attributes
      //Head
      const head_scripts = data.field_head_scripts.split('<!--NoDelay-->');
      let scriptElHead;
      if (data.field_head_scripts) {
        setTimeout(() => {
          if (head_scripts.length > 1) {
            scriptElHead = document.createRange().createContextualFragment(head_scripts[0]);
          } else {
            scriptElHead = document.createRange().createContextualFragment(data.field_head_scripts);
          }
          document.head.append(scriptElHead);
        }, 5000);
      }
      if (head_scripts.length > 1) {
        scriptElHead = document.createRange().createContextualFragment(head_scripts[1]);

        document.head.append(scriptElHead);
      }

      //Body
      // const scriptElBody = document.createRange().createContextualFragment(data.field_body_scripts);
      // if (data.field_body_scripts) { document.body.prepend(scriptElBody); }
      const body_scripts = data.field_body_scripts.split('<!--NoDelay-->');
      let scriptElBody;
      if (data.field_body_scripts) {
        setTimeout(() => {
          if (body_scripts.length > 1) {
            scriptElBody = document.createRange().createContextualFragment(body_scripts[0]);
          } else {
            scriptElBody = document.createRange().createContextualFragment(data.field_body_scripts);
          }
          document.head.append(scriptElBody);
        }, 5000);
      }
      if (body_scripts.length > 1) {
        scriptElBody = document.createRange().createContextualFragment(body_scripts[1]);

        document.head.append(scriptElBody);
      }

      //Footer
      // const scriptElFooter = document.createRange().createContextualFragment(data.field_footer_scripts);
      // if (data.field_footer_scripts) { document.body.append(scriptElFooter); }
      const footer_scripts = data.field_footer_scripts.split('<!--NoDelay-->');
      let scriptElFooter = document.createRange().createContextualFragment(footer_scripts.length > 1 ? footer_scripts[0] : data.field_footer_scripts);
      if (data.field_footer_scripts) {
        setTimeout(() => {
          if (body_scripts.length > 1) {
            scriptElFooter = document.createRange().createContextualFragment(footer_scripts[0]);
          } else {
            scriptElFooter = document.createRange().createContextualFragment(data.field_footer_scripts);
          }
          document.head.append(scriptElFooter);
        }, 5000);
      }
      if (footer_scripts.length > 1) {
        scriptElFooter = document.createRange().createContextualFragment(footer_scripts[1]);

        document.head.append(scriptElFooter);
      }
    })
  }
  const loadAppLinks = (whichHeader, loadParent = false) => {
    axios.get(`${DRUPAL_API_ENDPOINT}/jsonapi/block_content/country_wise_links?filter[field_country_language_][value]=${whichHeader}`).then((res) => {
      if (res.data?.data.length) {
        dispatch(setGlobalLinks(res.data?.data[0]))
      } else {
        if (loadParent) {
          loadAppLinks('/', true)
        }
      }
    })
  }
  useEffect(() => {
    loadDynamicScript()
    const path = window.location.pathname.split('/')
    let whichHeader = '/'
    if (path.length >= 2) {
      whichHeader = path[1]
      loadAppLinks(whichHeader, true)
    } else {
      loadAppLinks(whichHeader)
    }
    setTimeout(() => setFooter(true), 1000);
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 767 && !hasScrolled) {
        setHasScrolled(true);
      }
    };
    if (window.innerWidth > 767) {
      setHasScrolled(true);
    }
    window.addEventListener('scroll', handleScroll);

    const handleClick = () => {
      if (window.innerWidth <= 767 && isFirstClick.current) {
        isFirstClick.current = false;
        setHasScrolled(true);
      }
    };
    document.addEventListener('click', handleClick);

    const handleTouchMove = (e) => {

      if (window.innerWidth <= 767 && !hasScrolled) {
        const currentY = e.touches[0].clientY;
        if (currentY > 0) {
          setHasScrolled(true);
        }
      }
    };

    window.addEventListener('touchmove', handleTouchMove);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('click', handleClick);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, [hasScrolled]);
  return (
    <>
      <Menu />
      {/* <div className="h100-pc"></div> */}
      {props.children}
      {footer && hasScrolled && (
        <>
          <Footer />
          <SocialMedia />
        </>
      )}
    </>
  );
};

export default Layout;
