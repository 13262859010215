import React, { useState, useEffect } from "react";
import styles from "../Loader/Loader.module.css";
import gif from "../../assets/image/Loader.gif";

function Loader() {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowContent(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  if (!showContent) {
    return null;
  }
  return (
    <>
      {/* <div className={styles.loader}>
        <div
          className={`spinner-border ${styles.spinner__border__color}`}
          style={{ width: "5rem", height: "5rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div> */}
      <div className={`${styles.gif} ${styles.loader}`}>
        <img className={styles.loading_gif} alt="" src={gif} />
        <div className={styles.gif_title} >Please give us a minute, we’re handling this<br />…as well as dozens of relocations right now.</div>
      </div>
    </>
  );
}

export default Loader;
