import React, { useEffect, useState } from 'react'
import axios from 'axios'
import APIS from '../../../config/api'
import Image from '../Image'
import { Link } from "react-router-dom";
import Utils from '../../../config/utils'
import {
  DRUPAL_API_ENDPOINT
} from "../../../constants/drupalApiEndPoints";


export default function MenuItemTitleIconMobile(props) {
  const [componentDetail, setComponentDetails] = useState(props.item)
  // function getComponentDetails(id) {
  //   axios.get(APIS.MEGA_MENU_ITEM + id).then((res) => {
  //     const data = res.data.data
  //     setComponentDetails(data)
  //   }).catch(() => {
  //     // console.log('error')
  //   }).then(() => {
  //     // console.log('error')
  //   })
  // }
  // useEffect(() => {
  //   getComponentDetails(props.keyId)
  // }, [props.keyId])
  function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }
  return (
    <li>
      <div className="menu-content menu-first-content">
        <a href={Utils.parseDrupalURL(componentDetail?.field_mega_menu_link)}>
          {(componentDetail?.field_mega_menu_icon) ? <img src={DRUPAL_API_ENDPOINT + componentDetail?.field_mega_menu_icon} className='map-icon' /> : null}
          <span className="p-text">
            {htmlDecode(componentDetail?.field_mega_menu_title) }
          </span>
        </a>
      </div>
    </li>

  )
}