import React, { useEffect, useState } from 'react'
export default function FooterItem(props) {
  const [componentDetail, setComponentDetails] = useState(props.item)
  // const getComponentDetails = (id) => {
  //   axios.get(APIS.FOOTER_MENU_ITEM + id).then((res) => {
  //     const data = res.data.data
  //     setComponentDetails(data)
  //   }).catch(() => {
  //     // console.log('error')
  //   }).then(() => {
  //     // console.log('error')
  //   })
  // }
  // useEffect(() => {
  //   getComponentDetails(props.keyId)
  // }, [props.keyId])
  function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }
  return (
    <li>
      {/* <Link to={Utils.parseDrupalURL(componentDetail?.attributes?.field_menu_link?.uri)}> */}
        <span className='adress'>
          <span className={componentDetail?.field_menu_title}></span> 
          <div dangerouslySetInnerHTML={{ __html: htmlDecode(componentDetail?.field_menu_link) }}></div>
          
        </span>
      {/* </Link> */}
    </li>
  )
}
