import React, { useEffect, useState } from 'react'
import axios from 'axios'
import APIS from '../../../config/api'
import ComponentDecider from '../ComponentDecider'
import MenuItemTitleIconMobile from './MenuItemTitleIconMobile'
export default function MenuContentMobile(props) {
  const [componentDetail, setComponentDetails] = useState(props.item)

  // function getComponentDetails (id) {
  //   axios.get(APIS.MEGA_MENU_COTAINER + id).then((res) => {
  //     const data = res.data.data
  //     setComponentDetails(data)
  //   }).catch(() => {
  //     // console.log('error')
  //   }).then(() => {
  //     // console.log('error')
  //   })
  // }

  // useEffect(() => {
  //   getComponentDetails(props.keyId)
  // }, [props.keyId])


  return (
    <div className="header-menus-wrap about_us_menu_wrap active">
    <div className="flex-row">
      <div className="menu-list">
        <ul>
          {
            componentDetail.submenu.map((item) => {
              return <MenuItemTitleIconMobile item={item} key={item.uuid} />
            })
          }
        </ul>
      </div>
    </div>
   </div> 
  )
}