class CustomStorage {
    constructor() {
        this.storage = window.sessionStorage;
    }

    set(key, value) {
        this.storage.setItem(key,value);
    }

    get(key) {
        return this.storage.getItem(key);
    }

    remove(key) {
        this.storage.removeItem(key);
    }

    clear() {
        this.storage.clear();
    }
}
export default new CustomStorage();